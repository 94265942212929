import React,{Suspense} from 'react'
import PropTypes from 'prop-types'
import Loader from '../../adapters/helpers/Loader'
/** The oralB button */

const Button = ({
    children,
    tag: Tag,
    whiteTheme,
    size,
    className,
    dataActionDetail,
    greyTheme,
    invertedTheme,
    borderTheme,
    borderThemeBlue,
    textTheme,
    styles,
    href,
    target,
    role,
    ariaLabel,
    type,
    dataCustom,
    dataActionLabel,
    disabled,
    sku,
    onClick,
    id,
    ariaDescribedBy,
    title}) => {

    if (!children) {
        return null
    }

    className = className ? `ob-button ${className}` : 'ob-button';

    return (
        <Suspense fallback={<Loader />}>
        <Tag
            className={className}
            data-action-detail={dataActionDetail}
            data-size={size}
            data-white-theme={whiteTheme}
            data-action-label={dataActionLabel}
            data-grey-theme={greyTheme}
            data-inverted-theme={invertedTheme}
            data-border-theme={borderTheme}
            data-border-theme-blue={borderThemeBlue}
            data-text-theme={textTheme}
            data-custom={dataCustom}
            data-sku={sku}
            type={Tag === 'button' && type ? type : undefined}
            href={['a'].indexOf(Tag) > -1 && href ? href : undefined}
            target={['a', 'form'].indexOf(Tag) > -1 ? target : undefined}
            formTarget={Tag === 'button' ? target : undefined}
            rel={target === '_blank' ? 'noopener noreferrer' : undefined}
            role={role}
            aria-label={ariaLabel}
            style={styles}
            disabled={disabled}
            onClick={onClick}
            id={id}
            aria-describedby={ariaDescribedBy}
            title={title}
        >
            <span dangerouslySetInnerHTML={{__html: children}}></span>
        </Tag>
        </Suspense>
    )
}

Button.propTypes = {
    /** Children is the text button */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),

    /** Becomes white background button
     * with primary blue text if set to true*/
    whiteTheme: PropTypes.bool,

    /** Transparent with border and
     * color white if set to true*/
    invertedTheme: PropTypes.bool,

    /** Color and Border grey if set to true */
    greyTheme: PropTypes.bool,

    /** Increase the height with the value: 'large'*/
    size: PropTypes.string,

    /** Custom class. */
    className: PropTypes.string,

    /** Analytics data action event */
    dataActionDetail: PropTypes.string,

    /** Aria-label is mandatory if not children in the button */
    ariaLabel: PropTypes.string,

    /** Custom styles to be applied to the button */
    styles: PropTypes.object,

    /**
    * The tag or component to be used e.g. button, a, Link
    */
    tag: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func
    ]),

    /**
    * The target for the button
    */
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),


    /** You can set an Href. */
    href: PropTypes.string,

    /**
     * The type the button
     */
    type: PropTypes.string,

    role: PropTypes.string,

    borderTheme: PropTypes.bool,

    borderThemeBlue: PropTypes.bool,

    textTheme: PropTypes.bool,

    dataCustom: PropTypes.string,

    onClick: PropTypes.any,

    productVariantColor: PropTypes.any,

    dataActionLabel: PropTypes.string,

    disabled: PropTypes.bool,

    sku: PropTypes.string,
    id: PropTypes.string,

    ariaDescribedBy: PropTypes.string,

    title: PropTypes.string,

};

Button.defaultProps = {
    tag: 'button',
    styles: {}
}

export default Button;


