import React,{Suspense} from 'react';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('../Image/Image'));
import PropTypes from 'prop-types';
import {findIndex} from '../../adapters/helpers/functions';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { ResponsiveImageConstants } from '../../adapters/helpers/Constants'
import Loader from '../../adapters/helpers/Loader';
function ResponsiveImage({document, isXSmall, isMedium, isXLarge}) {
    const classNames = document?.fields?.classNames || '';

    // We try to tind the smallest possible possible format
    const formats = [
        [ResponsiveImageConstants.tiny, document?.fields?.tinyImage, isXSmall],
        [ResponsiveImageConstants.mobile, document?.fields?.mobileImage, isMedium],
        [ResponsiveImageConstants.tablet, document?.fields?.tabletImage, isXLarge],
        [ResponsiveImageConstants.desktop, document?.fields?.desktopImage, true],
    ]

    let currentFormat = [...formats]
        .reverse()
        .reduce((prev, curr) => curr[2] ? curr[0]: prev, undefined);

    let image = undefined;

    if(currentFormat) {
        /*
            If there is a format we get the index of the format
            and then we find the closest image to the format
        */
        const index = findIndex(formats, format => format[0] === currentFormat);
        for(let i = index; i < formats.length; i++) {
            const format = formats[i];
            if(format[1]) {
                image = format[1];
                break;
            }
        }
    }

    if(!image) {
        /* If there is still no images we get the largest available image */
        image = formats
            .reduce((prev, curr) => curr[1] ? curr[1]: prev, undefined);
    }

    if(!image) {
        /* We return null if the if the formats array contains no images */
        return null;
    }

    return (
        <Suspense fallback={<Loader />}>
        <div className={classNames}>
        <Suspense fallback={<Loader />}><Image image={image} /></Suspense>
        </div>
        </Suspense>
    );
}

export default mediaQueryHOC(ResponsiveImage);

ResponsiveImage.propTypes = {
    document: PropTypes.object,
    extraAttributes: PropTypes.object,
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
  }
